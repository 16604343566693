<template>
  <div class="">
    <header-page :header="headers"></header-page>
    <v-container>
      <v-row class="w-full">
        <v-col>
          <header-search-page :header="headersSearch" :loader="loader.loadSearchResult" :result="searchDetails"
            @close="closeSearch" @action="searchActions">
          </header-search-page>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="w-full">
        <v-col cols="6" class="my-auto mx-auto text-center" v-if="loader.loadSearchResult">
          <!-- <p>Loading companies</p> -->
          <v-progress-circular :size="70" :width="7" color="secondary" indeterminate></v-progress-circular>
        </v-col>
        <v-template v-else>
          <v-col cols="6" sm="6" md="4" lg="3" v-for="(item, i) in searchCompany" :key="i">
            <company-card :company="item"></company-card>
          </v-col></v-template>
      </v-row>

      <v-row>
        <v-col class="d-flex flex-column">
          <h1 class="secondary--text my-0 pb-2" width="auto">
            Most populars companies
            <!-- <hr class="secondary" /> -->
          </h1>
          <p>Here most populars companies selected for you</p>
          <!-- <v-avatar size="2em" >
                        <v-icon color="white" class="secondary">{{ icons.mdiArrowDecisionOutline }}
                        </v-icon>
                    </v-avatar> -->
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row v-if="loader.loadrecomduser === true">
        <v-col cols="6" sm="4" md="3" v-for="index in 4" :key="index">
          <v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions">
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="4" md="3" v-for="(item, index) in companiesList" :key="index">
          <company-card :company="item"></company-card>
        </v-col>
        <v-col cols="12">
          <v-btn class="mx-auto text-center" color="primary" :disabled="loadmore.disablebtn" @click="loadmorecompany()">
            Load
            more</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-2 rounded-lg">
            <div class="pa-4">
            </div>
            <v-card-text class="">
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Some releases app -->
    <!-- <some-releases></some-releases> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import {
  mdiMagnify, mdiMapMarkerOutline, mdiDomain,
  mdiHelpCircleOutline, mdiTransitConnectionHorizontal,
  mdiArrowRightThinCircleOutline, mdiArrowLeftThinCircleOutline
} from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import CompanyCard from '@/views/components/company/CompanyCard.vue'
import Company from '@/views/components/company/Company.vue'
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import HeaderSearchPage from '@/views/components/HeaderSearchPage.vue'
import SomeReleases from './components/SomeReleases.vue'


export default {
  components: {
    CompanyCard,
    Company,
    HeaderPage,
    HeaderSearchPage,
    SomeReleases
  },
  data() {
    return {

      no_resultitem: 0,
      searchDetails: '',
      loader: {
        loadSearchResult: false,
        loadrecomduser: true,
        loaderdata: false,
        isImgLoaded: false,
      },
      // Current page
      loadmore: {
        disablebtn: false,
        currentpage: 1,
        perpage: 4
      },

      searchCompany: [],

      companiesList: [],
      search: '',
      headers: {
        titlepage: 'Everything for compannies here.',
        icon: this.icons.mdiTransitConnectionHorizontal
      },
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      headersSearch: {
        title: "Search companies",
        subtitle: "Search and networking with the most companies",
        icon: '',
        search: {
          offer: {
            text: 'Search by offer',
            icon: mdiMagnify
          },
          location: {
            text: 'Search by location',
            icon: mdiMapMarkerOutline
          },
          domain: {
            text: 'Search by domain',
            icon: mdiDomain
          },
          company: {
            text: 'Search by company',
            icon: mdiDomain
          },
        }
      },
    }
  },
  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    }
  },

  computed: {
    searching() {
      if (!this.search || this.search.trim().length < 2) return { isEmpty: true, data: [] }
      else {
        return {
          isEmpty: false, data: this.companiesList.filter(item => {
            const text = item.name.toLowerCase()
            return text.indexOf(this.search.toLowerCase().trim()) > -1
          })
        }
      }
    },
  },
  methods: {
    slidePrev() {
      // this.$refs.carousel.slidePrev();
    },
    slideNext() {
      // this.$refs.carousel.slideNext();
    },
    updateCarousel(payload) {
      // this.myCarouselData = payload.currentSlide;
    },

    loadImage() {
      this.loader.isImgLoaded = true;
    },

    closeSearch() {
      this.loader.loaderdata = true
      this.searchCompany = []
    },

    searchActions(data) {
      this.loader.loadSearchResult = true
      this.loader.loaderdata = true
      const mainInputText = data.title.isEmpty === false ? data.title.value : ''
      const locationInputText = data.location.isEmpty === false ? data.location.value : ''
      const domainInputtext = data.domain.isEmpty === false ? data.domain.value : ''
      const companyInputtext = data.company.isEmpty === false ? data.company.value : ''

      // console.log(data)
      this.searchCompany = []
      Drequest.api(`enterprise.search?name=${mainInputText}&location=${locationInputText}`)
        .get((response) => {
          if (response.success === true) {
            response.listEntity.forEach((element, index) => {
              this.searchCompany.push(element)

            });
            this.searchDetails = response.detail
            this.no_resultitem = response.nb_element
            this.loader.loadSearchResult = false
            this.loader.loaderdata = false
          }
          else {
            this.loader.loadSearchResult = false
            this.searchDetails = response.detail
            this.loader.loaderdata = false
            this.no_resultitem = 0
          }

        })
        .catch((err) => {
          this.loader.loadSearchResult = false
          this.loader.loaderdata = false

          this.no_resultitem = 0
        });
    },

    loadmorecompany() {
      this.loadmore.currentpage = this.loadmore.currentpage + 1
      this.loadCompany(this.loadmore.currentpage, this.loadmore.perpage)
    },
    loadCompany(nextpage, perpage) {
      Drequest.api(`lazyloading.enterprise?dfilters=on&user_id:neq=${this.$store.getters.getUser.id}&next=${nextpage}&per_page=${perpage}`)
        .get((response) => {
          if (response.success === true) {
            response.listEntity.forEach((value, index) => {
              this.companiesList.push(value)
            })
            this.loader.loaderdata = false

            this.loader.loadrecomduser = false
            if (response.nb_element === this.companiesList.length) {
              this.loadmore.disablebtn = true
            }
            else {
              this.loadmore.disablebtn = false
            }
          } else {
            this.$fire({
              title: "Erreur inattendue!",
              type: "error",
              text: "Veuillez recharger la page..!",
              timer: 2000
            })
          }
        })
        .catch((err) => {
          console.log("err")
          this.loader.loaderdata = false;
        })
    }
  },
  beforeMount() {
    this.loader.loaderdata = true
    this.loadCompany(this.loadmore.currentpage, this.loadmore.perpage)
  },

  setup() {
    const icons = {
      mdiMagnify, mdiMapMarkerOutline, mdiDomain,
      mdiHelpCircleOutline, mdiTransitConnectionHorizontal,
      mdiArrowRightThinCircleOutline, mdiArrowLeftThinCircleOutline
    }
    return {
      icons
    }
  },
}
</script>

<style lang="scss">
@import '~@/assets/css/style.scss';
</style>
