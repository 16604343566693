<template>
    <!-- <v-card tile flat class="component-bloc fill-height rounded-lg" height="auto" v-if="company != null"> -->
    <v-hover v-slot="{ hover }">
        <div class="component-bloc" v-if="company != null">
            <div>
                <v-list-item>
                    <v-list-item-avatar size="50">
                        <v-img src="@/assets/images/avatars/img-xl.svg" height="100%"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <div class="overline pa-2 d-flex flex-column">
                            <v-rating :value="4.5" color="primary" class="pa-0" dense half-increments readonly
                                size="15">
                            </v-rating>
                            <div class="white--text">4.5 (413)</div>
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </div>
            <div class="pa-4" v-if="!hover">
                <h3 class="cc-text">{{ company.name }}</h3>
                <!-- <p class="cc-text d-flex flex-wrap flex-row align-center">
                    <v-icon small color="secondaryAccent">{{ icons.mdiWeb }}</v-icon>
                    <span class="my-auto ml-1 mb-1 caption">{{ company.website == null ? "Unnamed" : company.website
                    }}</span>
                </p> -->
                <div>
                    <v-btn x-small block color="secondary" class="px-2 subsecondary rounded-lg" outlined text
                        @click="viewsCompanyDetails">
                        Details
                    </v-btn>
                </div>
            </div>

            <v-expand-transition>
                <div class="pa-2 ma-1 rounded-lg white m-m-m" v-if="hover">
                    <h3 class="cctext-hove">{{ company.name }}</h3>
                    <p class="cctext-hove text-caption limit-about-company">{{ company.about }}</p>
                    <!-- <h4 class="cctext-hove d-flex flex-wrap flex-row align-center">
                        <v-icon small color="">{{ icons.mdiWeb }}</v-icon>
                        <span class="my-auto ml-1 mb-1 caption">{{ company.website == null ? "No website" : company.website
                        }}</span>
                    </h4> -->
                    <div>
                        <v-btn x-small block color="subsecondary" class="px-2 secondary rounded-lg" outlined text
                            @click="viewsCompanyDetails">
                            more details
                        </v-btn>
                    </div>
                </div>
            </v-expand-transition>
        </div>
    </v-hover>
</template>


<script>
// eslint-disable-next-line object-curly-newline
import { mdiMapMarkerOutline, mdiWeb } from '@mdi/js'
import { ref } from '@vue/composition-api'
var md5 = require("md5")

export default {
    props: {
        company: {
            type: Object,
            default: null
        }
    },
    methods: {
        viewsCompanyDetails() {
            this.$router.push({ name: "company.views", params: { f_id: md5(this.company.id), ids: this.company.id } })
        }
    },
    setup(props) {
        const icons = { mdiMapMarkerOutline, mdiWeb }

        return {
            icons
        }
    },
}
</script>

<style lang="scss">
// @import '~@/assets/css/style.scss';

.cc-text {
    color: var(--v-secondaryAccent-base);
}
.cc-text-hove {
    color: var(--v-accent-base);
}

.component-bloc {
    position: relative;
    border: solid 1px rgb(171, 170, 175) !important;
    min-height: 100%;
    height: 15.5em;
    width: 10em;
    background: linear-gradient(rgba(48, 121, 190, 0.08), rgba(114, 169, 86, 0.08)), url('~@/assets/images/bgs/person-lead.png') center center / cover no-repeat;
    border-radius: 1em !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    vertical-align: top;
}

.limit-about-company {
    height: 3em !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
.m-m-m{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
