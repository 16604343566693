<template>
    <v-card outlined height="100%" class="d-flex flex-column align-center pa-3 mx-1 h-full">
        <v-card-text class="d-flex flex-row flex-nowrap align-center pa-0">
            <v-icon small class="mr-1">
                {{ icons.mdiHeart }}
            </v-icon>
            <span class="subheading mr-2">256</span>
            <span class="mr-1">·</span>
            <v-spacer></v-spacer>
            <v-btn small text color="secondary" clatextss="ubsecondary px-1" @click="followCompany">
                <v-icon small class="mr-1">
                    {{ icons.mdiSetCenter }}
                </v-icon>
                <span class="text-caption text-capitalize">Follow</span>
            </v-btn>
        </v-card-text>
        <v-avatar size="5em" color="white" class="">
            <v-img src="@/assets/images/avatars/profil.svg" contain height="5em"></v-img>
        </v-avatar>
        <v-card-subtitle>{{ company.name }}</v-card-subtitle>
        <v-card-text outlined class="rounded-lg px-0 h-full">
            <v-list-item-content>
                <div class="overline mb-2">
                    <v-row align="center" class="mx-0">
                        <v-rating :value="4.5" color="primary" dense half-increments readonly size="14">
                        </v-rating>

                        <div class="grey--text ml-4">4.5 (413)</div>
                    </v-row>
                </div>
                <div class="d-flex flex-wrap flex-row">
                    <v-icon small>{{ icons.mdiWeb }}</v-icon>
                    <span class="my-auto ml-1 mb-1 caption">{{ company.website === undefined ? "Unnamed" :
                            company.website
                    }}</span>
                </div>

                <div class="d-flex flex-wrap flex-row">
                    <v-icon small>{{ icons.mdiEmailOutline }}</v-icon>
                    <span class="my-auto ml-1 mb-1 caption">{{ company.website === undefined ? "Unnamed" :
                            company.email
                    }}</span>
                </div>

                <div class="d-flex flex-wrap flex-row">
                    <v-icon small>{{ icons.mdiMapMarkerOutline }}</v-icon>
                    <span class="my-auto ml-1 mb-1 caption"></span>
                </div>

            </v-list-item-content>
        </v-card-text>
        <v-card-text class="rounded-lg pa-0">
            <v-btn outlined block color="secondary" class="text-caption subsecondary text-capitalize"
                @click="goToreferenceLink()">
                View
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import { mdiWeb, mdiEmailOutline, mdiPhoneOutline, mdiMapMarkerOutline, mdiHelpCircleOutline, mdiSetCenter, mdiHeart } from '@mdi/js'
var md5 = require("md5")

export default {
    props: {
        company: {
            type: Object,
            default: {}
        }
    },
    methods: {
        goToreferenceLink() {
            this.$store.commit("setCompany", this.company);
            this.$router.push({name: "company.views", params: { f_id: md5(this.company.id), ids: this.company.id }})
        },
        followCompany(){
            
        }
    },
    setup(props) {
        const icons = { mdiWeb, mdiEmailOutline, mdiPhoneOutline, mdiMapMarkerOutline, mdiHelpCircleOutline, mdiSetCenter, mdiHeart }
        return {
            icons,
        }
    },
}
</script>

<style lang="scss">
</style>